:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 768px;
	--d-font: 'Carme', sans-serif;
	--d-font2: 'lulocleanw01-oneboldregular', sans-serif;
	--primary: #002c4f;
	--white: #fff;
	--black: #000;
	--grey: #b5b4b4;
	--grey2: #b3b4b5;
	--cal_not_available: #f15322;
	--font-size-html: 15px;
	--font-size-section-title: 16px;
	--font-size-btn: 16px;
	--font-size-nav: 16px;
	--font-size-unit-title: 15px;
	--font-size-feat-list: 15px;
	--font-size-footer-link: 16px;

	--font-size-text: 15px;
	--font-size-100: 16px;
	--font-size-90: 14px;
	--font-size-85: 13px;
	--font-size-80: 12px;


	--font-size-submenu: 15px;

	@media (max-width:480px) {
		--font-size-html: 13px;
		--font-size-section-title: 15px;
		--font-size-btn: 15px;
		--font-size-nav: 15px;
		--font-size-unit-title: 13px;
		--font-size-feat-list: 13px;
		--font-size-text: 13px;
		--font-size-100: 15px;
		--font-size-90: 13px;
		--font-size-85: 13px;
		--font-size-80: 13px;

	}

}