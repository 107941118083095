@import "font-awesome.css";

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@media (min-width:768px) {
		/*@mixin flexlayout row;*/
	}
}

.doc-box {
	text-align: left;
}

@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex-align-center {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}


.tick-li {
	list-style: none;
	padding: 0;
	text-transform: uppercase;

	li {
		padding-left: 30px;
		margin-bottom: 3px;
		text-transform: uppercase;
		color: var(--primary);

		&:before {
			content: "\f00c";
			color: var(--secondarycolor);
			position: absolute;
			left: 2px;
			font-family: fontawesome;
		}
	}
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

h3 {
	font-size: var(--font-size-100);
	text-transform: uppercase;
	color: var(--primary);
	margin-bottom: 15px;
	font-weight: bold;
}


.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.asd__wrapper--full-screen {
	z-index: 50 !important;
}

.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}

body {
	font-family: var(--d-font);
	text-transform: uppercase;
	color: var(--primary);
	font-weight: 400;
	letter-spacing: 2px;
	font-size: var(--font-size-html);
}

.slider.slider-horizontal {
	width: 100%;
}


.form-horizontal {
	.control-label {
		text-align: left;
		padding-bottom: 5px;
	}
}


.asd__mobile-close {
	background-color: var(--black);
	color: var(--white);
	width: 36px;
	border-radius: 5px;
	margin-right: 10px;
}

.form-control {
	&.text-white {
		color: var(--white);

		&::-webkit-input-placeholder {
			/* Edge */
			color: var(--white);
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: var(--white);
		}

		&::placeholder {
			color: var(--white);
		}
	}

	&::-webkit-input-placeholder {
		/* Edge */
		color: var(--primary);
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: var(--primary);
	}

	&::placeholder {
		color: var(--primary);
	}

	border:1px solid var(--primary);
	font-size: 18px;
	padding: 5px 22px;
	font-weight: 500;
	color: var(--primary);
	text-transform:uppercase;
	border-radius:0;
}

.contact-form {

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.checkbox {
	label {
		font-weight: 400;
		margin-top: -2px;
	}
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}


.tooltip.top .tooltip-arrow {
	border-top-color: var(--primary);
}

.tooltip-inner {
	background-color: var(--primary);
	border: 1px solid var(--primary);
	color: var(--white);
	border-radius: 0;
}

.control-label {
	text-align: left;
	font-weight: 400;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: #ffd566 !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: rgb(103, 246, 238) !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--selected {
		background: #f7be29 !important;
		color: white !important;
		opacity: 1 !important;
		border-color: #f7be29 !important;
	}

	.asd__day--enabled.asd__day--hovered {
		border-color: #ffd566 !important;
		background: #ffd566 !important;
		opacity: 1 !important;
	}

	.asd__day--in-range {
		border-color: #ffd566 !important;
		background: #ffd566 !important;
	}




}

strong {
	font-family: 'lulocleanw01-oneboldregular', sans-serif;
}

.btn {
	border-radius: 0;
	font-size: var(--font-size-btn);
	padding: 5px 20px;
	text-transform: uppercase;
	font-family: 'lulocleanw01-oneboldregular', sans-serif;
	border: 2px solid;
	white-space: break-spaces;

	&.btn-primary {
		background-color: var(--primary);
		color: var(--white);
		border-color: var(--primary);

		&:hover {
			background-color: var(--white);
			border-color: var(--primary);
			color: var(--primary);
		}
	}

	&.btn-go {
		background-color: var(--grey2);
		color: var(--white);
		border-color: var(--grey2);

		&:hover {
			background-color: var(--white);
			border-color: var(--primary);
			color: var(--primary) !important;
		}
	}

	&.btn-default {
		color: var(--black);
		border-color: var(--primary);

		&.btn-sm {
			font-size: 12px;
		}

		&:hover {
			border-color: var(--primary);
			background-color: var(--primary);
			color: var(--white);
		}
	}

	&.btn-sm {
		font-size: 14px;
		padding: 6px 12px;
	}

	&.btn-secondary {
		background-color: transparent;
		color: var(--white);
		border-color: var(--white);

		&:hover {
			background-color: var(--grey2);
			border-color: var(--white);
			color: var(--white);
		}
	}

	&:focus {
		outline: 0 !important;
		text-decoration: none !important;
	}
}



/*
.pswp {
	display: none;
}
*/

a:focus,
.btn:focus,
span:focus,
button:focus,
label:before:focus,
.btn:focus {
	outline: 0 !important;
	text-decoration: none !important;
}

.guestbox {
	.btn {
		font-size: 20px !important;
		padding-left: 7px !important;

		&.btn-default {
			color: var(--primary) !important;
			border-color: var(--grey) !important;
			min-height: inherit !important;

			&:hover {
				color: var(--white) !important;
				border-color: var(--primary) !important;
				background-color: var(--primary) !important;
			}
		}
	}
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
	outline: 0 !important;
	outline-offset: 0 !important;
}


.checkbox input[type=checkbox]:focus+label:before {
	outline: inherit !important;
	outline: 0 !important;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--primary);
	border-color: var(--primary);
}


button,
select {
	text-transform: none;
	outline: none;
}

/*
.container-fluid,
.container {
	max-width: 1440px;
	width: 100%;
} */

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.pad60 {
	padding: 60px 0;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
	background-color: var(--primary);
	color: var(--white) !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.guestbox {
	.btn {
		width: 38px !important;
		height: 38px !important;
		border-radius: 0 !important;
		color: var(--primary);
		line-height: 24px !important;
	}

	label {
		color: var(--primary);
		text-transform: uppercase;
		font-weight: 400;
	}
}

@font-face {
	font-family: 'Font Awesome 5 Pro';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}


@font-face {
	font-family: 'lulocleanw01-oneboldregular';
	src: url('RESOURCE/fonts/fontsfree-net-lulo-clean-w01-one-bold.woff2') format('woff2'),
		url('RESOURCE/fonts/fontsfree-net-lulo-clean-w01-one-bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'Carme';
	src: url('RESOURCE/fonts/Carme-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Carme-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Carme-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Carme-Regular.eot') format('opentype'),
		url('RESOURCE/fonts/Carme-Regular.svg') format('svg')
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-regular-400.eot");
	src: url("RESOURCE/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-regular-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-regular-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-regular-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("RESOURCE/fonts/fa-solid-900.eot");
	src: url("RESOURCE/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-solid-900.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-solid-900.woff") format("woff"),
		url("RESOURCE/fonts/fa-solid-900.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fa,
.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
}

@font-face {
	font-family: 'Font Awesome 5 Brands';
	font-style: normal;
	font-weight: 400;
	font-display: block;
	src: url("RESOURCE/fonts/fa-brands-400.eot");
	src: url("RESOURCE/fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-brands-400.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-brands-400.woff") format("woff"),
		url("RESOURCE/fonts/fa-brands-400.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
	font-family: 'Font Awesome 5 Brands';
	font-weight: 400;
}


h1,
h2,
h3,
h4 {
	font-family: 'lulocleanw01-oneboldregular';
	line-height: 1.6;
}

@font-face {
	font-family: 'lulocleanw01-oneregular';
	src: url('RESOURCE/fonts/fontsfree-net-lulo-clean-w01-one.woff2') format('woff2'),
		url('RESOURCE/fonts/fontsfree-net-lulo-clean-w01-one.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}


h1 {
	@media (max-width:992px) {
		font-size: 26px;
	}
}

h2 {
	@media (max-width:992px) {
		font-size: 20px;
	}
}

.asd__mobile-header h3 {
	font-size: 14px !important;
}

.section-title {
	border-top: 2px solid var(--primary);
	border-bottom: 2px solid var(--primary);
	padding: 25px 0;
	text-align: center;
	text-transform: uppercase;
	color: var(--primary);
	font-size: var(--font-size-section-title);
	font-weight: 600;
}

.mb-15 {
	margin-bottom: 15px;
}

.pad30 {
	padding: 30px 0;
}

.pt-0 {
	padding-top: 0 !important;
}

.pt-30 {
	padding-top: 50px !important;

	@media (max-width:767px) {
		padding-top: 30px !important;
	}
}

.pt-15 {
	padding-top: 25px !important;

	@media (max-width:767px) {
		padding-top: 10px !important;
	}
}


.pb-0 {
	padding-bottom: 0 !important;
}

.pb-30 {
	padding-bottom: 50px !important;

	@media (max-width:767px) {
		padding-bottom: 30px !important;
	}
}

.pb-15 {
	padding-bottom: 25px !important;

	@media (max-width:767px) {
		padding-bottom: 10px !important;
	}
}

.fa-washer:before {
	content: "\f898";
}

.fa-dog-leashed:before {
	content: "\f6d4";
}

.far {
	font-family: 'Font Awesome 5 Pro' !important;
	font-weight: 400;
}

.asd__month-name {
	margin: 0 0 45px !important;
	font-size: 13px !important;
}

.unit-ft-icons {

	/*.icon {
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(RESOURCE/img/sprite-unit-icons-new.jpg);
		width: 40px;
		height: 42px;
		margin-right: 8px;
		display: inline-block;

		&.icon-nosmoke {
			background-position: -5px -5px;
		}

		&.icon-wifi {
			background-position: -61px -5px;
		}

		&.icon-pet {
			background-position: -440px -10px;
		}

		&.icon-washer {
			background-position: -501px -8px;
		}

		&.icon-spa {
			background-position: -547px -10px;
		}

		&.icon-clean {
			background-position: -187px -6px;
		}

		&.icon-tv {
			background-position: -127px -6px;
			width: 45px;
		}

		&.icon-sport {
			background-position: -246px -11px;
		}

		&.icon-sofa {
			background-position: -290px -10px;
		}

		&.icon-shopping {
			background-position: -336px -13px;
		}

		&.icon-nopets {
			background-position: -388px -12px;
		}

		&.icon-spa {
			background-position: -547px -10px;
		}

		&.icon-balkony {
			background-position: -599px -10px;
			width: 42px;
		}

		&.icon-vaccum {
			background-position: -657px -5px;
		}
	} */
	.icon {
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(RESOURCE/img/icon-stripe.png);
		width: 34px;
		height: 35px;
		margin-right: 8px;
		display: inline-block;

		&.icon-nosmoke {
			background-position: -5px -5px;
		}

		&.icon-wifi {
			background-position: -49px -8px;
		}

		&.icon-pet {
			background-position: -352px -8px;
		}

		&.icon-washer {
			background-position: -400px -9px;
		}

		&.icon-spa {
			background-position: -435px -10px;
		}

		&.icon-clean {
			background-position: -149px -10px;
		}

		&.icon-tv {
			background-position: -101px -9px;
			width: 45px;
		}

		&.icon-sport {
			background-position: -197px -11px;
		}

		&.icon-breadservice {
			background-position: -231px -11px;
		}

		&.icon-sofa {
			background-position: -290px -10px;
		}

		&.icon-shopping {
			background-position: -269px -10px;
		}

		&.icon-nopets {
			background-position: -310px -10px;
		}

		&.icon-spa {
			background-position: -435px -10px;
		}

		&.icon-balkony {
			background-position: -479px -8px;
			width: 42px;
		}

		&.icon-vaccum {
			background-position: -525px -7px;
		}
	}
}

.cookie-dlg {
	.footer {
		.btn {
			font-size: 14px;
			margin: 5px;
		}
	}
}

.alert-secondary {
	background-color: #e2e3e5;
	border-color: #d6d8db;
	color: #31708f;
	margin-top: 50px;
}

.description {
	p {
		margin-bottom: 30px;
	}
}

.page-header {
	border-bottom: 1px solid var(--grey);
}

h2 {
	font-size: var(--font-size-100);
}

.agb {
	li {
		list-style-type: none;
	}
}

.text-bold {
	font-weight: bold;
}

.cookie-dlg {
	.logo {
		height: 90px;
	}

	.section-title {
		padding-top: 10px;
		padding-bottom: 10px;
		margin-top: 20px;
		font-size: 14px;
	}

	.pt-30 {
		padding-top: 25px !important;
	}
}