.static-view {

	.inner-page-banner {
		width: 100%;
		height: 450px;
		overflow: hidden;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.feat-contnet {
		a {
			color: var(--primary);
		}

		p {
			font-size: var(--font-size-text);
		}
	}

	.flex-cols {
		display: inline-block;
		border-top: 1px solid var(--grey);
		border-right: 1px solid var(--grey);

		.feat-col-bl {
			float: left;
			padding: 40px;
			border-bottom: 1px solid var(--grey);
			border-left: 1px solid var(--grey);

			@media (max-width:992px) {
				padding: 20px;
			}

			&.pull-right {
				@media (max-width:992px) {
					float: left !important;
					width: 100%;
				}
			}

			&.pull-left {
				@media (max-width:992px) {
					float: left !important;
					width: 100%;
				}
			}

			.feat-img {
				height: 480px;
				overflow: hidden;
				margin-bottom: 30px;

				@media (max-width:767px) {
					height: 360px;
				}

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}

			h3 {
				margin-bottom: 30px;

				@media (max-width:767px) {
					font-size: 16px;
					margin-bottom: 20px;
				}

				span {
					font-family: var(--d-font);
				}
			}

			@media (min-width:993px) {
				width: 50%;
			}

			p {
				line-height: 1.5;
			}

			&.style1 {
				.feat-img {
					@media (min-width:993px) {
						height: 954px;
					}
				}
			}
		}

	}

	.ct-form-section {
		>.row {
			display: flex;

			>.col-sm-5 {
				display: flex;

				.aside-widget {
					padding: 20px 15px;
					width: 100%;

					background-color: var(--grey);
					font-size: 13px;

					h3 {
						margin-top: 0;
					}
				}
			}
		}
	}



	.contact-bl {
		display: flex;
		margin-bottom: 15px;
		position: relative;
		padding-left: 60px;
		min-height: 40px;
		-webkit-box-align: center;
		align-items: center;

		i {
			color: var(--white);
			background-color: var(--primary);
			padding: 5px;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			left: 0;
			top: 2px;
			box-shadow: 0 0 10px rgba(0, 0, 0, .18);
			font-size: 18px;
		}
	}

	.newsletter-form-group {
		.col-sm-12 {
			display: flex;
			flex-wrap: wrap;

			.form-control {
				width: 50%;

				@media (max-width: 767px) {
					width: 100%;
				}
			}

			.btn.btn-go {
				margin: 0 0 0 20px;

				@media (max-width: 767px) {
					margin: 15px 0 0 0;
					padding: 5px 9px;
				}
			}
		}
	}

}