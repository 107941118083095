.booking-view {
	.btn.btn-go {}

	.page-header {
		margin-top: 0;
	}

	.form-group {
		.form-control {
			padding-left: 10px;
			font-size: 16px;
			height: auto;
		}

		select.form-control {
			padding-left: 5px;
		}
	}

	.cart-details {
		padding: 14px;
		/*background-color: var(--grey);*/
		margin-top: 60px;
		max-width: 480px;

		.price-table {
			.total {
				td {
					font-size: 16px;
					color: var(--secondary);

					@media (max-width:1100px) {
						font-size: 12px;
					}
				}
			}

			td {
				.btn {
					display: none !important;
				}
			}

			.price {
				color: var(--primary) !Important;
			}
		}

		@media (max-width:992px) {
			margin-top: 30px;
		}

		.unit-details {
			margin: 20px 0;
			color: var(--black2);
			padding: 0;
			list-style: none;

			li {
				padding-left: 30px;
				padding-right: 15px;
				margin-top: 5px;
				position: relative;
				font-size: 15px;

				@media (max-width:1100px) {
					font-size: 12px;
				}

				&:before {
					content: "\f00c";
					font-family: "Font Awesome 5 Free";
					-webkit-font-smoothing: antialiased;
					font-weight: 600;
					display: inline-block;
					position: absolute;
					left: 0;
					top: 0;
					color: var(--primary);
				}
			}
		}

		.price {
			color: var(--primary);
			text-align: right;
			font-weight: bold;
		}
	}

	.pd-form {


		label {
			font-weight: 400;
		}

		a {
			color: var(--primary);
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 400;
				margin-top: -2px;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {}


}