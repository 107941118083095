@import "setup.css";

.vo-nav-bar {

	.navbar-bl {
		padding: 0;
		margin: 10px auto;
		border-top: 2px solid var(--primary);
		border-bottom: 2px solid var(--primary);

		@media (max-width:992px) {
			display: none;
		}

		li {
			list-style: none;
			display: inline-block;
			padding: 25px 10px;

			a {
				text-transform: uppercase;
				font-family: var(--d-font2);
				font-size: var(--font-size-nav);
				padding: 5px;
				color: var(--primary);
				letter-spacing: 2px;

				&:hover {
					text-decoration: none;
					color: var(--black);
				}
			}
		}

		.submenu {
			z-index: 999;
			position: absolute;
			margin-top: 40px;
			background: #fff;
			text-align: left;
			padding-left: 0px;
			font-family: var(--d-font);
			display: none;

			@media (max-width:992px) {
				position: relative;
				margin-top: 38px;
				background: none;
				text-align: center;
				display: none;
			}



			li {
				display: block;
				padding: 10px 5px;
				text-align: left;
				border-bottom: 1px solid var(--grey);

				@media (max-width:992px) {
					border: none;
					text-align: center;
				}


				a {
					font-family: var(--d-font);
					width: 100%;
					font-size: var(--font-size-submenu);
					font-weight: 400;
				}
			}
		}
	}

	.logo {
		display: inline-block;
		margin-top: 50px;
		margin-bottom: 40px;

		@media (max-width:992px) {
			margin-top: 40px;
			margin-bottom: 20px;
		}

		@media (max-width:768px) {
			margin-top: 35px;
			margin-bottom: 15px;
		}

		@media (max-width:480px) {
			margin-top: 25px;
			margin-bottom: 0px;
		}
	}

	.logo-standard,
	.logo-bakker,
	.logo-likedeeler,
	.logo-borkum,
	.logo-polarstern,
	.logo-spoekenkieker {
		width: 100%;

		@media (max-width:992px) {
			width: 90%;
		}

		@media (max-width:768px) {
			width: 70%;
		}

		@media (max-width:480px) {
			width: 60%;
		}
	}

	&.opened {
		.toggle-btn {
			z-index: 6;

			.icon-bar {
				right: 0;
				top: 0;

				&:nth-child(2) {
					display: none;
				}

				&:nth-child(1) {
					transform: rotate(45deg);
					right: -2px;
					top: 4px;
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
					right: -2px;
					top: -2px;
				}


			}


		}

		.navbar-bl {
			@media (max-width:992px) {
				padding: 30px 15px;
				width: 100%;
				position: fixed;
				left: 0;
				display: flex;
				background-color: var(--primary);
				top: 0;
				height: 100vh;
				z-index: 5;
				margin: 0;
				flex-direction: column;
				justify-content: center;
			}

			li {
				@media (max-width:992px) {
					display: block;
				}

				a {
					@media (max-width:992px) {
						color: var(--white);
					}
				}
			}
		}

	}

}

@media (min-width:993px) {
	.icon {
		display: none;
	}

}

.toggle-btn {
	display: none;
	background-color: var(--primary);
	padding: 10px;

	@media (max-width:992px) {
		position: absolute;
		right: 19px;
		top: 15px;
		display: inline-block;

	}

	.icon-bar {
		background-color: var(--white);
		width: 26px;
		position: relative;
		transition: all .3s ease;
		height: 2px;
		display: block;

		&:nth-child(2) {
			display: block;
		}

		&:nth-child(1) {
			transform: rotate(0);
		}

		&:nth-child(3) {
			transform: rotate(0);

		}

	}

	.icon-bar+.icon-bar {
		margin-top: 5px;
	}
}


@media screen and (max-width: var(--navbarbreak)) {}