.blog {}

.blog-card {
	margin-bottom: 20px;
	width: 100%;
	box-shadow: 0 0.1rem 1rem rgb(0 0 0/.1);
	background-color: #fff;
	background-color: var(--color-white);
	padding: 10px;

	&:hover {
		box-shadow: 0 4px 25px 0 rgb(0 0 0/50%);
		-webkit-transform: translateX(1px);
		transform: translateX(1px);
	}

	.blog-img {
		height: 330px;
		overflow: hidden;
		position: relative;

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
		}

		.date {
			position: absolute;
			font-size: .85rem;
			background-color: var(--primary);
			padding: 2px 5px;
			color: var(--white);
			bottom: 10px;
			right: 10px;
		}
	}

	.title {
		color: var(--black);
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		font-size: 13px;
		min-height: 63px;
	}


}

.blogentry {
	.tag-box {
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 5px 5px 0;

			a {
				background-color: #ddd;
				padding: 5px;
				display: block;

			}
		}
	}
}