.facility-view {

	.fa-logo {
		text-align: center;

		.logo-bakker {
			max-width: 230px;
		}
	}

	.description {

		border-bottom: 1px solid var(--grey);

	}



}