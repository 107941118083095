@import "common.css";

.unit-view {
	.btn-gallery {
		position: absolute;
		bottom: 15px;
		right: 15px;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	.unit-pictures {

		.row {
			@media (max-width:767px) {
				display: flex;
				flex-flow: row-reverse;
				flex-wrap: wrap;
			}
		}
	}

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.properties {
		margin-bottom: 30px;
	}

	.info-col-header {
		h3 {
			font-weight: bold;
			text-transform: uppercase;

			margin: 0 0 20px;
		}
	}

	.unit-serv-bl {
		margin-bottom: 0px;

		@media (min-width:768px) {
			min-height: 400px;
		}

		.unit-serv-img {
			width: 100%;
			height: 300px;
			overflow: hidden;

			@media (max-width:992px) {
				height: 280px;
			}

			@media (max-width:768px) {
				height: 280px;
			}

			@media (max-width:480px) {
				height: 280px;
			}



			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		h3 {
			font-weight: 600;
			font-size: var(--font-size-90);
			margin-bottom: 0px;
			margin-top: 10px;
			min-height: 24px;

			@media (max-width:768px) {
				font-size: var(--font-size-85);
			}

			@media (max-width:480px) {
				font-size: var(--font-size-90);
			}

			span {
				font-family: var(--d-font);
				font-weight: 300 !important;
			}

		}

		.unit-static-text {
			min-height: 50px;
			font-size: var(--font-size-90);

			@media (max-width:768px) {
				font-size: var(--font-size-85);
			}

			@media (max-width:480px) {
				font-size: var(--font-size-90);
			}
		}
	}

	.unit-list-section {
		.owl-item {
			.item {
				img {
					height: 700px;
					object-fit: cover;

					@media (max-width:767px) {
						height: 450px;
					}
				}
			}
		}

		.owl-nav {
			position: absolute;
			top: 42%;
			left: 0;
			width: 100%;

			.owl-prev {
				width: 40px;
				height: 40px;
				position: absolute;
				left: 10px;
				outline: none;
				display: -webkit-box;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;

				span {
					font-size: 50px;
					color: #fff;
					line-height: 1;
				}
			}

			.owl-next {
				width: 22px;
				height: 40px;
				position: absolute;
				right: 6px;
				left: auto;
				outline: none;
				display: flex;
				-webkit-box-align: center;
				align-items: center;
				-webkit-box-pack: center;
				justify-content: center;

				span {
					font-size: 50px;
					color: #fff;
					line-height: 1;
				}
			}
		}
	}

	.v-img-gallery {
		margin-top: 20px;

		.img-thumbnail {
			border: none;
			padding: 0;
			width: 100%;
			border-radius: 0;
		}
	}

	.search-section {
		.searchbox {
			margin: 1px;
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}

	.legend-cal {
		text-align: left;
		padding: 0;
		margin: 15px 0;

		ul {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-left: 0;
		}

		li {
			display: inline-flex;
			align-items: center;
			min-height: 30px;
			padding-right: 15px;
			margin: 5px 0;

			.day {
				width: 30px;
				height: 30px;
				background-color: #7e7975;
				display: inline-block;
				margin: 0 10px;
				border: 1px solid #e4e7e7;

				@media (max-width:992px) {
					width: 15px;
					height: 15px;
				}

				&.day-free {
					background: var(--white);
				}

				&.day-full {
					background: var(--cal_not_available);
				}

				&.day-end {
					background: linear-gradient(90deg, var(--cal_not_available) 20%, #fff 30%) !important;
				}

				&.day-start {
					background: linear-gradient(90deg, #fff 70%, var(--cal_not_available) 80%) !important;
				}
			}
		}
	}




	.main-section {
		position: relative;

		h2 {
			margin-top: 40px;
			font-size: var(--font-size-100);

			text-transform: uppercase;
			color: var(--primary);
			font-weight: 600;

			small {
				color: var(--primary);
			}

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.main-col {

			position: static;

			.v-img-gallery {
				.owl-item {
					a {
						display: block;
					}
				}

				.owl-nav {
					position: absolute;
					top: 35%;
					left: 0;
					width: 100%;

					.owl-prev {
						width: 40px;
						height: 40px;
						position: absolute;
						left: 15px;
						outline: none;
						display: -webkit-box;
						display: flex;
						-webkit-box-align: center;
						align-items: center;
						-webkit-box-pack: center;
						justify-content: center;
						font-size: 0;

						span {
							font-size: 50px;
							color: #fff;
							line-height: 1;
						}
					}

					.owl-next {
						width: 40px;
						height: 40px;
						position: absolute;
						right: 6px;
						left: auto;
						outline: none;
						display: flex;
						-webkit-box-align: center;
						align-items: center;
						-webkit-box-pack: center;
						justify-content: center;

						span {
							font-size: 50px;
							color: #fff;
							line-height: 1;
						}
					}
				}
			}

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 0 0 15px;

				.show-more {
					text-transform: uppercase;
					color: var(--primary);
					font-family: var(--d-font);
				}

				p {
					color: var(--primary);

					text-transform: uppercase;
					margin-bottom: 20px;
					line-height: 1.8;
				}
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}



			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.properties {


			strong {
				font-weight: normal !important;
			}

			.prop {
				padding-top: 5px;
			}


			.prop.missing {
				text-decoration: line-through;
				font-weight: normal;
				display: none;
			}

		}

		.info-col {
			border: 1px solid var(--grey);
			padding: 20px;
			max-width: 800px;
			margin-top: 20px;

			@media (max-width:992px) {
				margin-bottom: 40px;
			}

			.info-col-main {
				.form-control {
					margin-bottom: 15px;
					font-size: var(--font-size-text);
					font-weight: 400;
					letter-spacing: 2px;

					padding: 8px 17px;
					height: auto;
				}

				.btn {
					white-space: normal;
				}
			}

			.subprice {

				padding: 0 0 15px;

				.filters {}


				.book-btn {
					margin-top: 0;
					margin-bottom: 10px;
					white-space: normal;
				}

				.btn-default {
					white-space: normal;
				}

				.flow-buttons {
					button {
						margin-left: 20px;
						width: 100%;

						&.btn-go {
							margin-top: 15px;
						}
					}
				}
			}




		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;
			padding: 0 0 60px 0;

			@media (max-width: 767px) {
				height: 500px;
				padding: 0 0 123px 0;
			}

			.mapnote {
				font-size: 14px;
				padding: 0 0 20px;
				background: #fff;

				@media (max-width: 767px) {
					font-size: 12px;
					min-height: 123px;
				}

			}

			>div {
				height: 100%;
			}

			&.no-map {
				padding: 0;

				.mapconsent {
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 15px;

					.doc-box {}

					.mapconsent-button {
						background: var(--primary);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--black);
						}
					}
				}

				>div {
					height: 100%;
				}
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}


		.page-header h1 {
			font-size: 24px;
		}

		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}




}


.facility-view {

	.reduced-width-container {
		max-width: 970px !important;

		.row {
			padding: 5px;
		}

	}
}

.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

.unit-proposals {
	background-color: #f8f8f8;
}