.indexpage {
	.unit-row {
		padding-bottom: 40px;
		margin-bottom: 40px;
		border-bottom: 1px solid var(--grey);

		.row {
			@media (min-width:767px) {
				display: flex;
				flex-flow: row-reverse;
				flex-wrap: wrap;
			}
		}

		.unit-title {
			font-weight: bold;
			letter-spacing: 2px;
			text-transform: uppercase;
			font-size: var(--font-size-unit-title);
			margin-bottom: 25px;
			color: var(--primary);
			margin-top: 0px;

			@media (max-width:767px) {
				padding-top: 10px;
			}

			span {
				font-family: var(--d-font);
				font-weight: 400;
				font-size: 17px
			}
		}

		.unit-feat-li {
			margin-bottom: 25px;
			padding: 0;

			li {
				list-style: none;
				text-transform: uppercase;
				color: var(--primary);

				font-size: var(--font-size-feat-list);
				padding: 3px 0;
			}
		}

		.unit-ft-icons {
			display: flex;
			flex-wrap: wrap;
			padding-top: 18px;

			i {
				font-size: 24px;
				color: var(--primary);
				margin-right: 10px;
			}
		}

		.unit-img-bl {
			height: 360px;
			width: 100%;
			overflow: hidden;
			position: relative;

			@media (max-width:992px) {
				height: 280px;
			}

			.btn {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

}