footer {
	text-align: center;


	ul {
		padding: 20px 0;
		border-top: 2px solid var(--primary);
		margin: 0;

		li {
			list-style: none;
			display: inline-block;
			margin: 0 5px;

			&:first-child {
				a {
					padding-left: 0;

					&:before {
						display: none;
					}
				}
			}

			a {
				text-transform: uppercase;
				font-size: var(--font-size-footer-link);
				color: var(--primary);
				padding: 8px 2px;
				position: relative;
				font-family: var(--d-font);
				padding-left: 30px;

				@media (max-width:767px) {
					padding: 0px 2px;
					padding-left: 0px;
				}


				&:before {
					width: 0;
					height: 0;
					content: "";
					width: 5px;
					height: 5px;
					border-radius: 50%;
					background-color: var(--white);
					margin-right: 3px;
					position: absolute;
					left: 0;
					top: 15px;
				}
			}
		}
	}
}