@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.flex-col {
	@media (min-width:767px) {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}
}

.vo-search-body {
	min-height: 100%;
	height: 100%;

	.result-pane {
		width: 100%;
		padding: 0px 13px;

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		h3 {
			font-size: var(--font-size-100);
		}

		.result-header {
			padding: 0px;
			border-bottom: 1px solid rgb(235, 235, 235);
			padding-bottom: 15px;
			margin-bottom: 15px;

			.sorting-trigger {
				cursor: pointer;
			}

		}


		.pagination-panel {
			text-align: center;
		}

	}

	.mysearch {
		margin-bottom: 4px;
		margin-top: 20px;
	}

	.map-view {
		.result-pane {
			display: none;
		}
	}

	.map-pane {
		height: 900px;
		width: 100%;

		&.affix {
			@media (min-width:768px) {
				position: sticky;
				margin-top: 15px !important;
				top: 15px;
			}
		}


		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}

	}

	.unit-pro-price {
		@media (min-width:768px) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			position: inherit;
			bottom: 0;
			width: 100%;
		}

		.rent-rate {
			font-size: 13px;
			text-transform: uppercase;

			color: var(--primary);

			@media (min-width:1341px) {
				text-align: right;
			}

			@media (max-width:450px) {
				margin-top: 18px;
			}

			strong {
				font-size: 20px;
				font-weight: 700;
				padding-right: 20px;
			}
		}

		.unit-ft-icons {
			display: flex;
			flex-wrap: wrap;

			@media (min-width:768px) {
				width: 60%;

			}
		}

		>.prices {
			@media (min-width:768px) {
				width: 40%;
				text-align: right;
			}
		}
	}

	.map-view {
		@media (min-width: 767px) {
			.col-md-4 {
				width: 100%;
			}

			.col-md-8 {
				width: 0%;
				padding: 0;
			}
		}
	}

	.offer-price-box {
		.main-price {
			text-decoration: line-through;
		}

		.reducedprice {
			color: red;

			span {
				font-size: 12px;
			}
		}
	}

	.alternatives {
		@media (min-width:767px) {
			min-height: auto;
			margin-top: 20px;
		}
	}



	.map-unit-preview {
		color: var(--primary);
		text-align: center;

		.text-truncate {
			color: var(--primary);
		}

		h6 {
			font-weight: 600;
		}

		a {
			color: inherit;
		}

		.main-price {
			text-decoration: line-through;
		}

		.reducedprice {
			color: red;
		}


		.rent-rate {
			color: var(--primary);

			strong {
				font-size: 20px;
			}
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}


	.more-btn-panel {
		padding-bottom: 40px;
		max-width: 450px;
		margin: 0 auto;
		margin-top: 50px;

		@media (min-width:768px) {
			display: none;
		}
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.unit-count-h3 {
		font-family: lulocleanw01-oneregular, sans-serif;
		font-weight: 400;

		font-size: var(--font-size-100);

		strong {
			font-family: lulocleanw01-oneboldregular;
			font-weight: 700;
		}
	}

	.results {

		padding: 0px 13px;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			width: 100%;
			position: relative;

			padding: 5px;
			padding-bottom: 15px;
			padding: 40px 0;
			border-bottom: 1px solid #e6e2e7;


			@media (min-width:768px) {
				flex-direction: row-reverse !important;
				min-height: 400px;
			}

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;

				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: var(--primary);
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.unit-img {
				width: 100%;
				height: 100%;

				.owl-carousel {
					overflow: hidden;
					height: 100%;

					.owl-stage-outer {
						height: 100%;

						.owl-stage {
							height: 100%;
						}
					}

					.unit-dist {
						padding: 0 10px;
						font-size: 12px;
						line-height: 30px;
						font-weight: 700;
						color: var(--white);
						background-color: var(--primary);
						z-index: 5;
						position: absolute;
						right: 0;
						bottom: 0;

					}

					.owl-item {
						height: 100%;
					}

					.item {
						height: 100%;

						img {
							width: 100%;
							height: 100%;
							-o-object-fit: cover;
							object-fit: cover;
						}
					}
				}
			}

			.owl-nav {
				position: absolute;
				top: 40%;
				left: 0;
				width: 100%;

				button {
					width: 40px;
					height: 40px;
					position: absolute;
					left: 5px;

					span {
						font-family: "Font Awesome 5 Free";
						font-weight: 900;
						-webkit-font-smoothing: antialiased;
						display: inline-block;
						font-style: normal;
						font-variant: normal;
						text-rendering: auto;
						line-height: 1;
						color: transparent;
						font-size: 34px;

						&:before {
							color: var(--white);
							font-family: "Font Awesome 5 Free";
							position: relative;
							left: 2px;
						}
					}

					&.owl-next {
						position: absolute;
						right: 6px;
						left: initial;

						span {

							&:before {
								content: "\f054";
							}
						}
					}

					&.owl-prev {
						span {
							left: 6px;
							position: relative;

							&:before {
								content: "\f053";

							}
						}
					}
				}
			}

			.img-col {
				@media (max-width:767px) {
					margin-bottom: 15px;
				}

				.img-content {
					position: relative;

					.btn {
						position: absolute;
						top: 50%;
						left: 50%;
						-webkit-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
						color: var(--white);
						z-index: 5;

						@media (max-width:992px) {
							font-size: 12px;
						}
					}

					.full-img {
						width: 100%;
						height: 100%;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: #fff;
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 0;
				background-color: white;

				@media (min-width:768px) {
					/*padding-bottom: 60px !important; */
				}

				.unit-title-area {
					margin-bottom: 25px;
				}

				.tick-li {
					li {
						letter-spacing: 2px;
						font-size: var(--font-size-feat-list);
						padding-top: 3px;
					}
				}

				.unit-feat-li {
					margin-bottom: 25px;
					padding: 0;

					li {
						list-style: none;
						text-transform: uppercase;
						color: var(--primary);

						font-size: var(--font-size-feat-list);
						padding: 3px 0;
					}
				}

				.text-truncate {
					color: var(--primary);
					text-transform: uppercase;
					font-family: lulocleanw01-oneregular, sans-serif;
					font-weight: 400;
					letter-spacing: 2px;
					font-size: var(--font-size-unit-title);
				}

				.unit-title {
					font-weight: 700;
					padding-right: 40px;

					text-transform: uppercase;
					font-size: 14px;
					color: var(--primary);

					span {
						font-family: lulocleanw01-oneregular, sans-serif;
						font-weight: normal;

					}

					.facility {
						font-size: 12px;
					}
				}

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}

				.offer-box {
					margin-top: 20px;
				}

			}
		}


	}


	.search-result-properties {
		display: inline-block;

		>div {
			float: left;
			text-align: center;
			padding: 3px;

			border-right: 1px solid #ccc;

			:last-child {
				font-weight: bold;
			}
		}

		>div:last-child {
			border: none;
		}

	}


	@media (min-width:768px) {
		.results {
			.srow {
				@mixin flexlayout row;
			}

			.img-col {
				@mixin flex;
				position: relative;

				.img-content {
					position: absolute;
					@mixin size100;


					.full-img {
						height: 100%;
						object-fit: cover;

					}
				}



				.unit-img {
					position: absolute;


				}
			}

			.info-col {
				width: 55%;
			}
		}
	}


	.search-banner1 {
		.banner-text {
			color: var(--primary);
			text-align: center;
			letter-spacing: 4px;

			h1 {
				font-family: 'lulocleanw01-oneregular';
				line-height: 1.5;
				margin: 0;
			}

			strong {
				display: block;
			}
		}
	}

	.search-bar {
		padding: 210px 50px 170px;

		@media (max-width:767px) {
			padding: 60px 0;
		}

		&.search-banner {
			background-image: url(RESOURCE/img/banner-frau.jpg);
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;

			.search-panel {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin: 0 -15px;

				@media (max-width:991px) {
					width: 90%;
					padding: 24px !important;
					margin: 0 auto;
				}

				.form-btn {
					padding: 0 0 15px;
					width: 100%;

					@media (min-width:992px) {
						width: 25%;
						padding: 0 15px;
					}
				}


				.form-flex {
					padding: 0 15px;

					&.datepicker {
						width: 100%;

						@media (min-width:992px) {
							width: 25%;
						}
					}


					&.guest-bl {
						width: 100%;

						@media (min-width:992px) {
							width: 25%;
						}
					}

					@media (max-width:991px) {
						width: 100%;
						padding: 0 0 15px;
					}
				}

				@media (max-width:991px) {
					display: block;
					padding: 0 15px;
				}

				.btn-default {
					width: 100%;
					white-space: initial;

					@media (max-width:991px) {
						display: block;

						text-align: center;
					}
				}
			}
		}

		label[for="cb_pets"] {
			color: var(--whitecolor);
			font-size: 15px;
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.9);
			padding: 15px;
		}

		.btn {
			margin: 2px;

			&.btn-default {
				border-color: var(--white);
				background-color: transparent;
				color: var(--white);
				min-height: 50px;
				font-size: 16px;
				letter-spacing: 2px;

				@media (max-width:1170px) {
					font-size: 14px;
				}

			}
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:767px) {

		.list-view {
			.map-pane {
				display: none;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			z-index: 50;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}

	.logo-row {

		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-auto-rows: minmax(50px, auto);
		grid-gap: 1em;
		grid-template-areas:
			'img1 img2 img3 img4 img5';



		text-align: center;
		border-bottom: 1px solid var(--grey);
		border-top: 1px solid var(--grey);
	}


}