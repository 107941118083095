@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.search-box-home {
		background-image: url(RESOURCE/img/banner-slide10-new.jpg);
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		text-align: center;
		min-height: 550px;
	}

	.banner-forms {

		.bannerone {
			background-image: url(RESOURCE/img/banner-slide5-new.jpg);
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: flex;
			align-items: center;
			min-height: 550px;
			justify-content: center;
		}


		.banner-text {
			color: var(--primary);
			text-align: center;
			letter-spacing: 4px;

			h1 {
				font-family: 'lulocleanw01-oneregular';
				line-height: 1.5;
				margin: 0;
			}

			strong {
				display: block;
			}
		}

		.search-box-home {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.search-bl-center {
				width: 100%;
			}
		}

		.search-form-area {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-flow: wrap;

			.form-flex {
				padding: 0 15px;

				&.datepicker {
					width: 100%;

					@media (min-width:993px) {
						width: 25%;
					}
				}


				&.guest-bl {
					width: 100%;

					@media (min-width:993px) {
						width: 25%;
					}
				}

				@media (max-width:992px) {
					width: 100%;
					padding: 0 0 15px;
				}

				.form-bx-area {
					position: relative;
					width: 100%;

				}

				.fa,
				.fal {
					position: absolute;
					right: 10px;
					top: 15px;
					color: #ccc;
					font-size: 20px;

					@media (max-width:992px) {
						top: 13px;
					}
				}


				.form-control {
					background-color: transparent;
					border: none;
					border-radius: 0;
					width: 100%;
					min-height: 50px;
					padding-right: 10px;
					padding-left: 10px;
					text-transform: uppercase;
					font-family: var(--d-font2);
					color: var(--white);
					border: 2px solid var(--white);
					text-align: center;
					font-size: 16px;
					letter-spacing: 2px;
					height: auto;

					@media (max-width:1170px) {
						font-size: 14px;
					}
				}

			}

			.form-btn {

				@media (max-width:992px) {
					width: 100%;
					padding: 0 0 15px;
				}

				.btn {
					background-color: transparent;
					border: none;
					border-radius: 0;
					width: 100%;
					height: 50px;
					padding-right: 10px;
					padding-left: 10px;
					text-transform: uppercase;
					font-weight: bold;
					font-family: var(--d-font2);
					color: var(--white);
					border: 2px solid var(--white);
					text-align: center;
					line-height: 35px;
					font-size: 16px;
				}
			}
		}
	}

	.banner-slide {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: -1;

		.owl-item {
			position: relative;
		}

		.item {
			width: 100%;
			height: calc(100vh - 248px);
			min-height: 767px;
			overflow: hidden;

			@media (max-width:992px) {
				min-height: 647px;
				height: calc(100vh - 170px);
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}


		}


		.owl-dots {
			bottom: 60px;
			width: 50%;
			left: 50%;
			transform: translate(-50%, 0);
			font-size: 27px;
			text-align: center;
			position: absolute;

			@media (max-width:992px) {
				display: none;
			}

			.owl-dot {
				width: 50px;
				height: 0;
				display: inline-block;
				border-bottom: 4px solid var(--white);
				margin: 0 4px;

				&.active {
					border-bottom-color: var(--primary);
				}
			}
		}
	}


	.search-box-home {
		text-align: center;
		width: 100%;
		padding: 0 15px;


		h2 {
			color: var(--white);
			text-transform: uppercase;
			font-family: 'lulocleanw01-oneregular';
			letter-spacing: 4px;
			margin-bottom: 20px;
			font-size: 30px;
		}

		@media (max-width:992px) {
			bottom: 18px;
		}
	}

	.searchbar {

		display: inline-block;
		text-align: left;

		@media (max-width:992px) {
			width: 90%;
			padding: 10px;
		}

		@media (min-width:993px) {
			max-width: 1170px;
			width: 100%;

		}

		.form-btn {
			padding: 0 0 15px;

			@media (min-width:993px) {
				width: 25%;
				padding: 0 15px;
			}
		}

		.searchbox {
			padding: 0;

			@media (max-width:992px) {
				padding-top: 10px;
			}

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}


	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}






}